<template>
  <div id="app">
    <div class="layout">
        <Layout>
            <Header class="layout-header" :style="{position: 'fixed', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#000000', zIndex: 9999}">
				<div class="c">
					<Menu class="layout-menu" mode="horizontal" theme="dark" active-name="1">
						<div class="layout-menu-box">
							<div class="layout-logo"></div>
							<div class="layout-nav">
								<MenuItem name="1" @click="handleScroll('about');">
									About
								</MenuItem>
								<MenuItem name="2" @click="handleScroll('technology');">
									Technology
								</MenuItem>
								<MenuItem name="3" @click="handleScroll('how');">
									How 2U Works
								</MenuItem>
								<MenuItem name="5" @click="handleScroll('sale');">
									Token Sale
								</MenuItem>
								<!-- <MenuItem name="6">
									Roadmap
								</MenuItem>
								<MenuItem name="7">
									Contact Us
								</MenuItem> -->
								<MenuItem name="8">
									<Button ghost shape="circle" size="default" @click="handleTrade()">Trade Now</Button>
								</MenuItem>
							</div>
							<div class="layout-nav-m" @click="sidebar = true;">
								<Icon type="ios-menu" size="24" color="#ffffff" />
							</div>
						</div>
					</Menu>
				</div>
            </Header>
            <Content :style="{margin: '60px 0px 0', minHeight: '1500px'}" >
				<div :style="{background: '#000000'}" ref="about">
					<div class="c" >
						<img src="./assets/landing.png" width="100%" style="display: block;" alt="" />
					</div>
					<div class="headline-box">
						<Row>
							<Col :xs="24" :sm="24" :md="8" :lg="8">
								<div class="headline">
									<div class="headline-img">
										<img src="./assets/growth.png" alt="" />
									</div>
									<div class="headline-text">Direct Stake in Innovation and Growth</div>
								</div>
							</Col>
							<Col :xs="24" :sm="24" :md="8" :lg="8">
								<div class="headline">
									<div class="headline-img">
										<img src="./assets/battery-charging.png" alt="" />
									</div>
									<div class="headline-text">Early Access to Revolutionary EV and Battery Market</div>
								</div>
							</Col>
							<Col :xs="24" :sm="24" :md="8" :lg="8">
								<div class="headline">
									<div class="headline-img">
										<img src="./assets/aperture_w.png" alt="" />
									</div>
									<div class="headline-text">Leveraged Returns through Strategic Partnerships</div>
								</div>
							</Col>
						</Row>
					</div>
					<div class="c">
						<div class="text1">
							2U's mission is built on pioneering the next wave of electric mobility with its patented battery technologies and smart connectivity solutions. 
							By investing in 2U Token, you are becoming part of a sustainable future that promises cleaner, more efficient transportation and energy use worldwide.
						</div>
					</div>
				</div>
				<div :style="{background: '#00E893'}" ref="technology">
					<div class="c" >
						<div class="text2-box">
							<div class="text2-img">
								<img src="./assets/text/2.svg" width="100%" alt="" />
							</div>
							<div class="text2-text">
								Powered by Brighsun 2U's cutting-edge technology, 2U is revolutionizing the way we think about energy, communication, and transportation.
								With a steadfast commitment to sustainability and innovation, we are crafting solutions for a greener planet.
								<br /><br />
								Our high-energy-density batteries and smart, connected vehicles stand at the forefront of an electric revolution,
								offering unparalleled efficiency and eco-friendliness.
							</div>
						</div>
					</div>
					<div class="c" >
						<div class="text3-box">
							<div class="text3-img">
								<img src="./assets/map.png" width="100%" alt="" />
							</div>
							<div class="text3-text">
								<img src="./assets/text/US$2.9 Million Funding Closed.svg" width="100%" alt="" />
								Our vision for the future is bold, and with every investment, we are turning potential into reality, powering a new era of electric vehicles and connected solutions.
								<br /><br />
								<Button type="default" ghost shape="circle" size="default" @click="handleTrade()" style="border-color: #000000;color: #000000;">Trade Now</Button>
							</div>
						</div>
					</div>
				</div>
				<div :style="{background: '#000000'}">
					<div class="c">
						<div class="text4-box">
							<div class="text4-img">
								<img src="./assets/text/US$200 million in R_D over 12 Years.svg" width="100%" alt="" />
							</div>
							<div class="text4-timeline">
								<Timeline>
									<TimelineItem>
										<p class="time">2012</p>
										<p class="content">R&D department is established.</p>
									</TimelineItem>
									<TimelineItem>
										<p class="time">2015</p>
										<p class="content">Achieved Guinness World Record for longest distance by electric bus on a single non-solar charge at 1,018 km (632.55 miles).</p>
									</TimelineItem>
									<TimelineItem>
										<p class="time">2023</p>
										<p class="content">Commercialization of semi-solid state battery. <br />Listed 2U Token (2UT) on Green-X digital exchange.</p>
									</TimelineItem>
									<TimelineItem>
										<p class="time">2024</p>
										<p class="content">Launching of eSUV with OEM by Chery and battery technology from 2U.</p>
									</TimelineItem>
								</Timeline>
							</div>
						</div>
					</div>
					<div class="c">
						<div class="text5-box">
							<div class="text5-img">
								<img src="./assets/text/Powering the Ecosystem of the Future.svg" width="100%" alt="" />
							</div>
						</div>
						<div class="text5-text">
							<div class="text5-bg"></div>
							<div class="text5-list">
								<div class="item item1">
									<div class="label">2U Semi-Solid State Battery</div>
									<div class="value">Longer range, higher recovery rate efficiency, adaptable to a wide range of temperatures.</div>
								</div>
								<div class="item item2">
									<div class="label">All-Electric Vehicles</div>
									<div class="value">Next-generation EV, partnering with Chery – the 3rd largest car manufacturer in China.</div>
								</div>
								<div class="item item3">
									<div class="label">Energy Storage</div>
									<div class="value">Safe and efficient, with a maximum energy density of 350 Wh/kg.</div>
								</div>
								<div class="item item4">
									<div class="label">2U.Chat App</div>
									<div class="value">All-in-one control center for the ecosystem of the future.</div>
								</div>
							</div>
						</div>
						<div class="text6-box">
							<div class="text6-img">
								<img src="./assets/text/Semi-Sold_Solid State Batteries.svg" width="100%" alt="" />
							</div>
							<div class="text6-text">
								<Row>
									<Col :xs="24" :sm="24" :md="8" :lg="8">
										<div class="headline">
											<div class="headline-value">
												1,000 km
											</div>
											<div class="headline-label">Driving Range In A Single Charge</div>
										</div>
									</Col>
									<Col :xs="24" :sm="24" :md="8" :lg="8">
										<div class="headline">
											<div class="headline-value">
												70%
											</div>
											<div class="headline-label">Recyclable Content</div>
										</div>
									</Col>
									<Col :xs="24" :sm="24" :md="8" :lg="8">
										<div class="headline">
											<div class="headline-value">
												350 W/h
											</div>
											<div class="headline-label">Maximum Energy Density</div>
										</div>
									</Col>
									<Col :xs="24" :sm="24" :md="8" :lg="8">
										<div class="headline">
											<div class="headline-value">
												-25℃ to 55℃
											</div>
											<div class="headline-label">Adaptable Temperature</div>
										</div>
									</Col>
									<Col :xs="24" :sm="24" :md="8" :lg="8">
										<div class="headline">
											<div class="headline-value">
												240℃
											</div>
											<div class="headline-label">Hot Box Test</div>
										</div>
									</Col>
									<Col :xs="24" :sm="24" :md="8" :lg="8">
										<div class="headline">
											<div class="headline-value">
												>30
											</div>
											<div class="headline-label">Extensive Safety Tests</div>
										</div>
									</Col>
								</Row>
							</div>
							<div class="text6-text2">
								<div class="img">
									<img src="./assets/GBWR logo.png" width="100%" alt="" />
								</div>
								<div class="text">
									<div class="label">
										World Record-Breaking Battery Performance
									</div>
									<div class="value">
										Brighsun developed the battery technology used in 2U.Chat eSUV reached a range of 1,018 km (632.55 miles), receiving the Guinness Book World Record for Greatest Distance by Electric Bus – achieved in a single non-solar charge in Melbourne, Australia.
									</div>
									<div class="value">
										Our technology redefines limits. Our semi-solid state batteries are ready to power the next generation of electric vehicles and e-bikes. Safer, more efficient, and designed for tomorrow, we're setting a new benchmark for energy solutions.
									</div>
								</div>
							</div>
						</div>
						<div class="text7-box"></div>
						<div class="text8-box" ref="how">
							<div class="text8-img">
								<img src="./assets/text/How 2U Works.svg" width="100%" alt="" />
							</div>
							<div class="text8-text">
								<div class="item">
									<div class="icon"><img src="./assets/shield 2.png" alt=""></div>
									<div class="value">
										<span>Patented Production Process</span><br />
										Utilizes anti-explosion sulfur-water silica aerogel and rolling core fixation tape for enhanced safety.
									</div>
								</div>
								<div class="item">
									<div class="icon"><img src="./assets/battery-charging 1.png" alt=""></div>
									<div class="value">
										<span>Advanced Battery Technologies</span><br />
										Incorporates semi-solid state batteries for superior energy density and efficiency.
									</div>
								</div>
								<div class="item">
									<div class="icon"><img src="./assets/zap 1.png" alt=""></div>
									<div class="value">
										<span>Seamless Integration</span><br />
										Batteries power electric vehicles (EVs) equipped with 2U.Chat for smart connectivity.
									</div>
								</div>
								<div class="item">
									<div class="icon"><img src="./assets/cpu 1.png" alt=""></div>
									<div class="value">
										<span>Intelligent Connectivity</span><br />
										2U.Chat enables real-time vehicle diagnostics, energy management, and user communication.
									</div>
								</div>
								<div class="item">
									<div class="icon"><img src="./assets/aperture_b.png" alt=""></div>
									<div class="value">
										<span>Strategic Partnerships</span><br />
										Collaboration with car manufacturers to embed 2U technology in a range of EVs.
									</div>
								</div>
								<div class="item">
									<div class="icon"><img src="./assets/target 2.png" alt=""></div>
									<div class="value">
										<span>Sustainable Impact</span><br />
										Focus on reducing environmental footprint while improving energy utilization in transportation.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div :style="{background: '#ffffff'}" ref="sale">
					<div class="c">
						<div class="text9-box">
							<div class="text9-img">
								<img src="./assets/text/Token Sale Details.svg" width="100%" alt="" />
							</div>
							<div class="text9-text">
								<div class="text">Brighsun’s 2U security token offering (STO) is regulated by Labuan Financial Services Authority (FSA), Syariah compliant, and meets the latest environmental, social and governance (ESG) standards.</div>
								<div class="sort-text">
									<strong>Get Ownership</strong> 
									<span>•</span>
									<strong>High Liquidity</strong>
									<span>•</span>
									<strong>Blockchain Secured</strong>
									<span>•</span>
									<strong>Regulatory Compliance</strong>
								</div>
								<div class="btn">
									<Button ghost shape="circle" size="default" @click="handleTrade()" :style="{width: '140px', height: '48px', color:'#000000', fontSize: '16px', border: '2px solid #000000'}">Trade Now</Button>
								</div>
							</div>
						</div>
						<!-- <div class="text12-box">
							<div class="text12-img">
								<img src="./assets/text/Roadmap.svg" width="100%" alt="" />
							</div>
							<div class="text12-list">
								<Timeline>
									<TimelineItem>
										<p class="time" style="color: #ffffff;">2024</p>
										<p class="content" style="text-align: left;line-height: 200%;">
											<strong>Q1</strong> – 2U.Chat eSUV arrived in Malaysia<br />
											<strong>Q2</strong> – Soft launch of 2U.Chat eSUV in Malaysia<br />
											<strong>Q3</strong> – Application of GBWR in 2U.Chat eSUV to achieve range of 1,000 km in a single charge<br />
											<strong>Q4</strong> – Grand launch of 2U.Chat eSUV
										</p>
									</TimelineItem>
									<TimelineItem>
										<p class="time" style="color: #ffffff;">2025</p>
										<p class="content" style="text-align: left;line-height: 200%;">
											<strong>Early</strong> – Brighsun to showcase off-grid EV charging facility prototype<br />
											<strong>Later</strong> – Launch of eSedan, eMPV, eBike, eBus, and eTruck
										</p>
									</TimelineItem>
								</Timeline>
							</div>
						</div> -->
					</div>
				</div>
				<div :style="{background: '#000000'}">
					<div class="c">
						<div class="text10-box">
							<div class="text10-img">
								<img src="./assets/text/Got questions_.svg" width="100%" alt="" />
							</div>
							<div class="text10-list">
								<Collapse simple v-model="vcollapse" :style="{background: 'none'}">
									<Panel v-for="(item,index) in faq" :key="index" :name="item.q" :style="{padding: '30px 0px', color: '#ffffff'}">
										<text :style="{color: '#ffffff', fontSize: '20px'}">{{item.q}}</text>
										<template #content>{{item.a}}</template>
									</Panel>
								</Collapse>
							</div>
						</div>
						<div class="text11-box">
							<div class="text11-img">
								<img src="./assets/text/Drive Change Together.svg" width="100%" alt="" />
							</div>
							<div class="text11-btn">
								<Button ghost shape="circle" size="default" :style="{width: '140px', height: '48px', margin:'0 10px', fontSize: '16px', border: '2px solid #ffffff'}">Trade Now</Button>
								<!-- <Button ghost shape="circle" size="default" :style="{width: '140px', height: '48px', margin:'0 10px', fontSize: '16px', border: '2px solid #ffffff'}">Contact Us</Button> -->
							</div>
						</div>
					</div>
				</div>
            </Content>
            <Footer class="layout-footer-center" :style="{background: '#000000', borderTop: '1px solid #333333'}">
				<div class="c" style="display: flex;justify-content: center;">
					<div style="font-size: 14px;color: #ffffff;">
						&copy; 2024 2U. All rights reserved.
					</div>
					<!-- <div></div> -->
				</div>
			</Footer>
        </Layout>
    </div>
	<Drawer title="" width="80%" :closable="false" v-model="sidebar" :styles="{background: '#000000'}">
		<div style="height: 80px;"></div>
		<Menu theme="dark" width="100%">
			<MenuItem name="1" :style="{borderBottom: '1px solid #222222', borderTop: '1px solid #222222'}" @click="sidebar = false; handleScroll('about');">
				About
			</MenuItem>
			<MenuItem name="2" :style="{borderBottom: '1px solid #222222'}" @click="sidebar = false; handleScroll('technology');">
				Technology
			</MenuItem>
			<MenuItem name="3" :style="{borderBottom: '1px solid #222222'}" @click="sidebar = false; handleScroll('how');">
				How 2U Works
			</MenuItem>
			<MenuItem name="5" :style="{borderBottom: '1px solid #222222'}" @click="sidebar = false; handleScroll('sale');">
				Token Sale
			</MenuItem>
			<!-- <MenuItem name="6">
				Roadmap
			</MenuItem>
			<MenuItem name="7">
				Contact Us
			</MenuItem> -->
			<MenuItem name="8" :style="{paddingTop: '40px'}">
				<Button ghost shape="circle" size="default" :style="{width: '100%', height: '48px'}" @click="handleTrade()">Trade Now</Button>
			</MenuItem>
		</Menu>
	</Drawer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
		sidebar: false,
		vcollapse: 'What is 2U Token?',
		faq: [
			{q: "What is 2U Token?",
			a: "2U Token is a digital asset representing an investment in the 2U ecosystem, focusing on pioneering electric vehicle (EV) battery technology and smart connectivity solutions through the 2U.Chat platform."},
			{q: "How does investing in 2U Token benefit investors?",
			a: "Investors benefit from the growth potential of 2U's innovative battery technology and electric mobility solutions. As 2U expands its market presence and technological advancements, the value of 2U Tokens is anticipated to increase, offering a promising return on investment."},
			{q: "What milestones has 2U achieved so far, and what are the future goals?",
			a: "2U has made significant progress in battery technology, including the successful pilot test of its lithium-sulfur battery and the advancement towards commercializing solid-state batteries. Future goals include expanding production capabilities, further developing the 2U.Chat platform, and securing additional strategic partnerships to enhance the ecosystem's reach and functionality."},
			{q: "What makes 2U's battery technology unique?",
			a: "2U's battery technology is unique due to its high energy density, safety features, and efficiency. The patented production process involves anti-explosion sulfur-water silica aerogel and rolling core fixation tape, setting new standards in the battery industry."},
			{q: "How will the funds raised from the 2U Token sale be utilized?",
			a: "Funds will support R&D, commercialization of battery technologies, expansion of the 2U.Chat platform, and strategic partnerships. This includes optimizing battery pack design, securing regulatory certifications, and enhancing intelligent systems for EVs."},
			{q: "What strategic partnerships does 2U have?",
			a: "2U has formed strategic partnerships with leading automobile manufacturers, like Chery Automobile, and entered agreements for the distribution and adoption of its electric vehicles and technology, demonstrating industry confidence and market penetration potential."},
			{q: "What are the risks associated with investing in 2U Token?",
			a: "Risks include market volatility, regulatory changes, and technological advancements by competitors. However, 2U's commitment to innovation and strategic partnerships aims to mitigate these risks and secure a competitive position."},
			{q: "How does 2U ensure the safety and efficiency of its battery technology?",
			a: "Through its patented production process and rigorous testing, 2U guarantees that its batteries meet high standards of safety and efficiency. Innovations like the sulfur-water silica aerogel significantly enhance battery safety features."},
			{q: "What future developments can investors expect from 2U?",
			a: "Investors can look forward to advancements in battery technology, broader adoption of the 2U.Chat platform, expansion into new markets, and further strategic partnerships, driving forward the electric mobility revolution."},
			{q: "How can I stay updated on 2U's progress and token sale details?",
			a: "Investors are encouraged to follow 2U's official communication channels, such as the company website, social media, and press releases, for the latest updates on project milestones, token sale phases, and strategic developments."}
		]
	}
  },
  methods: {
		handleTrade(){
			window.location.href="https://green-exchange.io/";
			return false;
		},
		handleScroll (target) {
			console.log('target', target)
			if(target == 'about'){
				this.$ScrollIntoView(this.$refs.about, {
					time: 1000,
					align: {
						top: 0.5, 
						topOffset: 100 
					}
				})
			}
			if(target == 'technology'){
				this.$ScrollIntoView(this.$refs.technology, {
					time: 1000,
					align: {
						top: 0.5, 
						topOffset: 100 
					}
				})
			}
			if(target == 'how'){
				this.$ScrollIntoView(this.$refs.how, {
					time: 1000,
					align: {
						top: 0.5, 
						topOffset: 100 
					}
				})
			}
			if(target == 'sale'){
				this.$ScrollIntoView(this.$refs.sale, {
					time: 1000,
					align: {
						top: 0.5, 
						topOffset: 100 
					}
				})
			}
		}
	}
}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-align: center; */
		color: #2c3e50;
	}
	.layout{
		border: none !important;
	}
	.c{
		margin: 0 auto;
	}
	@media screen and (min-width: 751px) {
		.c{
			width: 1440px;
		}
		.layout-nav{
			display: block;
		}
		.layout-nav-m{
			display: none;
		}
		.text1{
			padding: 80px 100px 160px 100px;
			font-size: 24px;
		}
		.headline-box{
			padding: 60px 300px 0px 300px;
			text-align: center; 
		}
		.headline{
			padding: 0px 60px;
		}
		.headline-text{
			font-size: 20px;
		}
		.headline-label{
			font-size: 16px;
			color: #ffffff;
			text-align: center;
		}
		.headline-value{
			font-size: 48px;
			color: #00E893;
			text-align: center;
		}
		.text2-box{
			padding: 160px 120px;
		}
		.text2-img{
			width: 800px;
		}
		.text2-text{
			text-align: left;
			padding: 30px 0 0 0;
			width: 900px;
			font-size: 16px;
			line-height: 28px;
		}
		.text3-box{
			padding: 0px 120px 160px 120px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.text3-img{
			width: 600px;
		}
		.text3-text{
			width: 500px;
			text-align: left;
			font-size: 16px;
			line-height: 28px;
		}
		.text4-box{
			padding: 160px 120px;
		}
		.text4-img{
			width: 1000px;
		}
		.text4-timeline{
			padding: 60px 0px 0 0;
		}
		.text4-timeline .time{
			color: #ffffff;
			text-align: left;
		}
		.text4-timeline .content{
			color: #ffffff;
			text-align: left;
		}
		
		.ivu-timeline-item-tail{
			border-left: 1px solid #00E893 !important;
			left: 34px  !important;
		}
		.ivu-timeline-item-head{
			display: none !important;
		}
		.ivu-timeline-item-content{
			padding-left: 0 !important;
			padding-bottom: 60px !important;
			display: flex;
		}
		.ivu-timeline-item-content .time{
			width: 120px;
			height: 48px;
			border: 1px solid #00E893;
			border-radius: 6px;
			font-size: 30px;
			text-align: center;
			color: #00E893;
			background-color: #000000;
		}
		.ivu-timeline-item-content .content{
			width: 980px;
			font-size: 18px;
			padding-left: 100px;
			padding-top: 6px;
			text-indent: 0;
		}
		.text5-box{
			padding: 0 120px 160px 120px;
		}
		.text5-img{
			width: 640px;
		}
		.text5-text{
			position: relative;
			height: 960px;
		}
		.text5-bg{
			width: 690px;
			height: 721px;
			background: url('assets/image.png') no-repeat center center;
			z-index:1;
		}
		.text5-list{
			position: absolute;
			left: 660px;
			top: 80px;
			z-index: 99
		}
		.text5-list .item{
			height: 180px;
			position: relative;
		}
		.text5-list .item.item1{padding-left: 40px}
		.text5-list .item.item2{padding-left: 100px;}
		.text5-list .item.item3{padding-left: 100px;}
		.text5-list .item.item4{padding-left: 0px}
		.text5-list .item.item1:before,
		.text5-list .item.item2:before,
		.text5-list .item.item3:before,
		.text5-list .item.item4:before{
			content: '';
			position: absolute;
			width: 50px;
			height: 50px;
			border: 3px solid #00E893;
			border-radius: 100%;
			background-color: #000000;
			left: -104px;
			top: 0;
		}
		.text5-list .item.item2:before{
			width: 80px;
			height: 80px;
			left: -14px;
		}
		.text5-list .item.item3:before{
			width: 60px;
			height: 60px;
			left: -14px;
		}
		.text5-list .item.item4:before{
			width: 40px;
			height: 40px;
			left: -124px;
		}
		.text5-list .item.item1:hover:before,
		.text5-list .item.item2:hover:before,
		.text5-list .item.item3:hover:before,
		.text5-list .item.item4:hover:before{
			background-color: #00E893;
		}
		.text5-list .item .label{
			color: #ffffff;
			font-size: 24px
		}
		.text5-list .item .value{
			color: #8D918F;
			font-size: 16px;
		}
		.text6-box{
			padding: 0 120px 10px 120px;
		}
		.text6-img{
			width: 640px;
			margin: 0 auto;
		}
		.text6-text{
			padding-top: 60px;
		}
		.text6-text .headline{
			padding: 40px 0;
		}
		.text6-text2{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 160px;
		}
		.text6-text2 .img{
			width: 140px;
		}
		.text6-text2 .text{
			width: 1000px;
		}
		.text6-text2 .text .label{
			color: #ffffff;
			font-size: 20px
		}
		.text6-text2 .text .value{
			color: #8D918F;
			font-size: 16px;
			padding: 30px 0 0 0;
		}
		.text7-box{
			padding: 0 120px 160px 120px;
			border-bottom: 1px solid #222222;
		}
		.text8-box{
			padding: 160px 120px 160px 120px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}
		.text8-img{
			width: 360px;
		}
		.text8-text{
			width: 700px;
		}
		.text8-text .item{
			height: 126px;
			display: flex;
			align-items: flex-start;
		}
		.text8-text .item .icon{
			width: 80px;
		}
		.text8-text .item .value{
			color: #8D918F;
			font-size: 16px;
		}
		.text8-text .item .value span{
			font-size: 20px;
			color: #ffffff;
		}
		.text9-box{
			padding: 160px 120px 160px 120px;
		}
		.text9-img{
			width: 940px;
		}
		.text9-text{
			padding: 40px 0 0 0;
			line-height: 28px;
			font-size: 16px;
		}
		.text9-text .text{
			width: 960px;
		}
		.text9-text .sort-text{
			padding-top: 30px;
		}
		.sort-text span{
			margin: 0 15px;
		}
		.text9-text .btn{
			padding-top: 50px;
			padding-bottom: 160px;
			border-bottom: 1px solid #222222;
		}
		.text10-box{
			padding: 160px 120px 160px 120px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}
		.text10-img{
			width: 300px;
		}
		.text10-list{
			width: 720px;
			color: #ffffff;
			text-align: left;
		}
		.ivu-collapse-content{
			background-color: rgba(0,0,0,0) !important;
			padding: 16px !important;
			color: #8D918F !important;
			font-size: 16px !important;
		}
		.ivu-collapse>.ivu-collapse-item>.ivu-collapse-header{
			padding-left: 0 !important;
		}
		.ivu-collapse-content>.ivu-collapse-content-box{
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
		.text11-box{
			padding: 0 120px 120px 120px;
		}
		.text11-img{
			width: 1000px;
			margin: 0 auto;
		}
		.text11-btn{
			padding: 40px 0 0 0;
			text-align: center;
		}
		.text12-box{
			padding: 0 120px 120px 120px;
		}
		.text12-img{
			width: 450px;
		}
		.text12-list{
			padding-top: 120px;
		}
		.text12-list .ivu-timeline-item-tail{
			border-left: 1px solid #000000 !important;
		}
		.text12-list .ivu-timeline-item-content .time{
			border: 1px solid #000000  !important;
		}
		.ivu-menu-dark{
			background-color: rgba(0,0,0,0) !important;
		}
	}
	@media screen and (max-width: 750px) {
		.ivu-layout-header{
			padding: 0  18px !important;
		}
		.c{
			width: 100%;
		}
		.layout-nav{
			display: none;
		}
		.layout-nav-m{
			display: block;
		}
		.text1{
			padding: 20px 30px 50px 30px;
			font-size: 14px;
		}
		.headline-box{
			padding: 20px 30px;
		}
		.headline{
			padding: 20px 0;
		}
		.headline-text{
			font-size: 16px;
		}
		.headline-label{
			font-size: 16px;
			color: #ffffff;
			text-align: center;
		}
		.headline-value{
			font-size: 48px;
			color: #00E893;
			text-align: center;
		}
		.text2-box{
			padding: 160px 20px;
		}
		.text2-img{
			width: 100%;
		}
		.text2-text{
			text-align: left;
			padding: 30px 0 0 0;
			width: 100%;
			font-size: 16px;
			line-height: 28px;
		}
		.text3-box{
			padding: 0px 20px 160px 20px;
			/* display: flex;
			align-items: center;
			justify-content: space-between; */
		}
		.text3-img{
			width: 100%;
		}
		.text3-text{
			width: 100%;
			text-align: left;
			font-size: 16px;
			line-height: 28px;
		}
		.text4-box{
			padding: 160px 20px;
		}
		.text4-img{
			width: 100%;
		}
		.text4-timeline{
			padding: 60px 0px 0 0;
		}
		.text4-timeline .time{
			color: #ffffff;
			text-align: left;
		}
		.text4-timeline .content{
			color: #ffffff;
			text-align: left;
		}
		
		.ivu-timeline-item-tail{
			border-left: 1px solid #00E893 !important;
			left: 34px  !important;
		}
		.ivu-timeline-item-head{
			display: none !important;
		}
		.ivu-timeline-item-content{
			padding-left: 0 !important;
			padding-bottom: 60px !important;
			display: flex;
		}
		.ivu-timeline-item-content .time{
			width: 120px !important;
			height: 48px !important;
			line-height: 48px;
			border: 1px solid #00E893;
			border-radius: 6px;
			font-size: 18px !important;
			text-align: center;
			color: #00E893;
			background-color: #000000;
		}
		.ivu-timeline-item-content .content{
			width: 580px;
			font-size: 18px;
			padding-left: 20px;
			padding-top: 6px;
			text-indent: 0;
		}
		.text5-box{
			padding: 0 20px 60px 20px;
		}
		.text5-img{
			width: 100%;
		}
		.text5-text{
			position: relative;
			/* height: 960px; */
		}
		.text5-bg{
			width: 100%;
			height: 521px;
			background: url('assets/image.png') no-repeat center center;
			background-size: cover;
			z-index:1;
		}
		.text5-list{
			/* position: absolute;
			left: 660px;
			top: 80px;
			z-index: 99 */
			padding: 20px;
		}
		.text5-list .item{
			/* height: 180px; */
			position: relative;
			padding: 10px 0;
		}
		/* .text5-list .item.item1{padding-left: 40px}
		.text5-list .item.item2{padding-left: 100px;}
		.text5-list .item.item3{padding-left: 100px;}
		.text5-list .item.item4{padding-left: 0px}
		.text5-list .item.item1:before,
		.text5-list .item.item2:before,
		.text5-list .item.item3:before,
		.text5-list .item.item4:before{
			content: '';
			position: absolute;
			width: 50px;
			height: 50px;
			border: 3px solid #00E893;
			border-radius: 100%;
			background-color: #000000;
			left: -104px;
			top: 0;
		}
		.text5-list .item.item2:before{
			width: 80px;
			height: 80px;
			left: -14px;
		}
		.text5-list .item.item3:before{
			width: 60px;
			height: 60px;
			left: -14px;
		}
		.text5-list .item.item4:before{
			width: 40px;
			height: 40px;
			left: -124px;
		}
		.text5-list .item.item1:hover:before,
		.text5-list .item.item2:hover:before,
		.text5-list .item.item3:hover:before,
		.text5-list .item.item4:hover:before{
			background-color: #00E893;
		} */
		.text5-list .item .label{
			color: #ffffff;
			font-size: 24px
		}
		.text5-list .item .value{
			color: #8D918F;
			font-size: 16px;
		}
		.text6-box{
			padding: 60px 20px 10px 20px;
		}
		.text6-img{
			width: 100%;
			margin: 0 auto;
		}
		.text6-text{
			padding-top: 60px;
		}
		.text6-text .headline{
			padding: 20px 0;
		}
		.text6-text2{
			/* display: flex;
			align-items: center;
			justify-content: space-between; */
			padding-top: 160px;
			text-align: center;
		}
		.text6-text2 .img{
			width: 140px;
			margin: 0 auto;
		}
		.text6-text2 .text{
			width: 100%;
		}
		.text6-text2 .text .label{
			color: #ffffff;
			font-size: 20px
		}
		.text6-text2 .text .value{
			color: #8D918F;
			font-size: 16px;
			padding: 30px 0 0 0;
		}
		.text7-box{
			padding: 0 20px 60px 20px;
			border-bottom: 1px solid #222222;
		}
		.text8-box{
			padding: 160px 20px 160px 20px;
		}
		.text8-img{
			width: 100%;
		}
		.text8-text{
			width: 100%;
			padding-top: 20px;
		}
		.text8-text .item{
			height: 126px;
			display: flex;
			align-items: flex-start;
		}
		.text8-text .item .icon{
			width: 64px;
		}
		.text8-text .item .value{
			color: #8D918F;
			font-size: 16px;
			padding-left: 10px;
		}
		.text8-text .item .value span{
			font-size: 20px;
			color: #ffffff;
		}
		.text9-box{
			padding: 160px 20px 160px 20px;
		}
		.text9-img{
			width: 100%;
		}
		.text9-text{
			padding: 40px 0 0 0;
			line-height: 28px;
			font-size: 16px;
		}
		.text9-text .text{
			width: 100%;
		}
		.text9-text .sort-text{
			padding-top: 30px;
		}
		.sort-text strong{
			display: block;
		}
		.sort-text span{
			margin: 0 15px;
			display: none;
		}
		.text9-text .btn{
			padding-top: 50px;
			padding-bottom: 160px;
			border-bottom: 1px solid #222222;
		}
		.text10-box{
			padding: 160px 20px 160px 20px;
			/* display: flex;
			align-items: flex-start;
			justify-content: space-between; */
		}
		.text10-img{
			width: 100%;
		}
		.text10-list{
			width: 100%;
			color: #ffffff;
			text-align: left;
			padding-top: 20px;
		}
		.ivu-collapse-content{
			background-color: rgba(0,0,0,0) !important;
			padding: 24px 16px 16px 16px !important;
			color: #8D918F !important;
			font-size: 16px !important;
		}
		.ivu-collapse>.ivu-collapse-item>.ivu-collapse-header{
			padding-left: 0 !important;
			line-height: 24px !important;
		}
		.ivu-collapse-content>.ivu-collapse-content-box{
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
		.text11-box{
			padding: 0 20px 120px 20px;
		}
		.text11-img{
			width: 100%;
			margin: 0 auto;
		}
		.text11-btn{
			padding: 40px 0 0 0;
			text-align: center;
		}
		.text12-box{
			padding: 0 20px 120px 20px;
		}
		.text12-img{
			width: 100%;
		}
		.text12-list{
			padding-top: 120px;
		}
		.text12-list .ivu-timeline-item-tail{
			border-left: 1px solid #000000 !important;
		}
		.text12-list .ivu-timeline-item-content .time{
			border: 1px solid #000000  !important;
		}
		.ivu-menu-dark{
			background-color: rgba(0,0,0,0) !important;
		}
	}
	.layout{
		border: 1px solid #d7dde4;
		background: #f5f7f9;
		position: relative;
		border-radius: 4px;
		overflow: hidden;
	}
	.layout-header,
	.layout-menu{
		background-color: #000000 !important;
	}
	.layout-menu-box{
		max-width: 1440px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.layout-logo{
		width: 173px;
		height: 34px;
		background: url('assets/logo3.png') no-repeat center center;
	}
	.layout-nav{
		margin: 0 auto;
		margin-right: 20px;
	}
	.layout-footer-center{
		text-align: center;
		justify-content: center;
		align-items: center;
	}
	.dev-run-preview .dev-run-preview-edit{ 
		display: none 
	}
	.text1{
		color: #8D918F;
		font-family: 'Inter','Arial';
	}
	.headline-img{
		padding-bottom: 10px;
		text-align: center;
	}
	.headline-text{
		color: #ffffff;
		text-align: center;
	}
</style>
